<div tabindex="-1" #top class="govuk-util__no-focus">
  <a class="govuk-skip-link" href="#main-content" (click)="skip($event)">Skip to main content</a>
  <app-header [showNotificationsLink]="standAloneAccount || parentAccount || subsAccount"></app-header>
  <div>
    <ng-container
      *ngIf="
        (standAloneAccount && newHomeDesignFlag && !isAdminSection) ||
          (parentAccount && newHomeDesignParentFlag && !isAdminSection) ||
          (subsAccount && newHomeDesignParentFlag && !isAdminSection);
        else otherView
      "
    >
      <div *ngIf="parentAccount || viewingSubsidiaryWorkplace">
        <app-navigate-to-workplace-dropdown [maxChildWorkplacesForDropdown]="31"></app-navigate-to-workplace-dropdown>
      </div>
      <app-stand-alone-account
        *ngIf="!viewingSubsidiaryWorkplace"
        [dashboardView]="dashboardView"
        data-testid="stand-alone-account"
      >
      </app-stand-alone-account>
      <app-subsidiary-account
        *ngIf="viewingSubsidiaryWorkplace"
        [dashboardView]="dashboardView"
        data-testid="subsidiary-account"
      >
      </app-subsidiary-account>
    </ng-container>
    <ng-template #otherView>
      <div
        id="content"
        [class]="{ 'govuk-width-container': !isAdminSection, 'asc-width-container-admin-section': isAdminSection }"
      >
        <app-phase-banner></app-phase-banner>
        <app-back-link></app-back-link>
        <app-new-back-link></app-new-back-link>
        <app-breadcrumbs></app-breadcrumbs>
        <app-alert></app-alert>

        <main
          id="main-content"
          class="govuk-main-wrapper app-main-class govuk-util__no-focus"
          #content
          role="main"
          tabindex="-1"
        >
          <router-outlet> </router-outlet>
        </main>
      </div>
    </ng-template>
  <app-help-and-tips-button *ngIf="showHelpButton" data-testid="help-and-tips-button"/>
  </div>
  <app-footer></app-footer>

</div>
